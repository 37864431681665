import React from "react";

import { Table, Pagination } from "antd";
import type { PaginationProps } from "antd";
import type { ColumnsType } from "antd/es/table";

import styles from "./Tables.module.css";
import { IWalletLog } from "@src/interfaces/adminInterfaces";

const walletColumns: ColumnsType<IWalletLog> = [
  {
    title: "ID",
    dataIndex: "id",
    key: "id",
  },
  {
    title: "Wallet Id",
    dataIndex: "walletId",
    key: "walletId",
  },

  {
    title: "Eski Bakiye",
    dataIndex: "currentBalance",
    key: "currentBalance",
  },
  {
    title: "Değişim Miktarı",
    dataIndex: "changeAmount",
    key: "changeAmount",
  },

  {
    title: "Güncel Bakiye",
    dataIndex: "finalBalance",
    key: "finalBalance",
  },
  {
    title: "Wallet Trans. Id",
    dataIndex: "walletTransactionId",
    key: "walletTransactionId",
  },
  {
    title: "Info",
    dataIndex: "info",
    key: "info",
  },
  {
    title: "Tarih",
    dataIndex: "createdAt",
    render: (date) => new Date(date).toLocaleString("tr-TR"),
    key: "createdAt",
  },
];

interface Props {
  data: IWalletLog[];
}
const WalletLogsTable: React.FC<Props> = ({ data }) => {
  return (
    <>
      <Table
        className={styles.table}
        columns={walletColumns}
        dataSource={data}
        pagination={false}
        rowKey="id"
        summary={() => <Table.Summary fixed={"top"}></Table.Summary>}
        sticky
        bordered
        scroll={{ x: 960 }}
      />
    </>
  );
};

export default WalletLogsTable;

import { Button, Tag, Tooltip } from "antd";
import type { ColumnsType } from "antd/es/table";
import { CloseOutlined, CheckOutlined, CopyOutlined } from "@ant-design/icons";

import {
  User,
  Subscriptions,
  Invoices,
  ErrorLog,
  Courses,
  Coupons,
  Transactions,
  PackagePlans,
  IWallet,
  IAnnouncements,
} from "../../interfaces/adminInterfaces";

import adminService from "../../services/adminService";
import { API_IMAGE_URL } from "../../config";

class columnsList {
  usersColumns: ColumnsType<User> = [
    {
      title: "Name",
      render: (record) => {
        return `${record.firstName} ${record.lastName}`;
      },
      key: "firstName",
    },
    {
      title: "email",
      dataIndex: "email",
      key: "email",
      width: "20%",
    },
    {
      title: "isSeller",
      render: (record) => {
        return record?.isSeller === true ? (
          <Tag color="#87d068">YES</Tag>
        ) : (
          <Tag color="#f50">NO</Tag>
        );
      },
      key: "isSeller",
    },
    {
      title: "inCommunity",
      render: (record) => {
        return record?.inCommunity === true ? (
          <Tag color="#87d068">YES</Tag>
        ) : (
          <Tag color="#f50">NO</Tag>
        );
      },
      key: "inCommunity",
    },
    {
      title: "isVerified",
      render: (record) => {
        return record?.isVerified === true ? (
          <Tag color="#87d068">YES</Tag>
        ) : (
          <Tag color="#f50">NO</Tag>
        );
      },
      key: "isVerified",
    },
    {
      title: "status",
      render: (record) => {
        return record?.status === "active" ? (
          <Tag color="#87d068">{record.status}</Tag>
        ) : (
          <Tag color="#f50">{record.status}</Tag>
        );
      },
      key: "status",
    },
    {
      title: "role",
      render: (record) => {
        return record?.role?.name !== "user" ? (
          <Tag color="#f50">{record?.role?.name}</Tag>
        ) : (
          record.role.name
        );
      },
      key: "role",
    },
    {
      title: "lifeTimeCourse",
      render: (record) => {
        return record?.lifeTimeCourse ? (
          <Tag color="#87d068">YES</Tag>
        ) : (
          <Tag color="#f50">NO</Tag>
        );
      },
      key: "lifeTimeCourse",
    },
    {
      title: "isNewPassword",
      render: (record) => {
        return record?.isNewPassword ? (
          <Tag color="#87d068">YES</Tag>
        ) : (
          <Tag color="#f50">NO</Tag>
        );
      },
      key: "isNewPassword",
    },
  ];

  supplierOrdersSummaryColumns: ColumnsType<any>=[
    {
      title:"Sipariş Günü",
      render:(record)=>{
        return new Date(record?.order_day)?.toLocaleString("tr-TR")
      },
      key:"order_day"
    },
    {
      title:"Müşteri Ciro",
      render:(record)=>{
        return `${Number(record?.seller_order_total).toFixed(2)} USD`
      },
      key:"seller_order_total"
    },
    
    {
      title:"Rexven Ciro",
      render:(record)=>{
        return `${Number(record?.commission_price_sum).toFixed(2)} USD`
      },
      key:"commission_price_sum"
    },
    {
      title:"Toplam Ürün Ödeme",
      render:(record)=>{
        return `${Number(record?.total_sum).toFixed(2)} USD`
      },
      key:"total_sum"
    },
    {
      title:"Toplam Kargo Ücreti",
      render:(record)=>{
        return `${Number(record?.shipment_cost_sum).toFixed(2)} USD`
      },
      key:"shipment_cost_sum"
    },
    {
      title:"Tasarım Ücreti",
      render:(record)=>{
        return `${Number(record?.design_price_sum).toFixed(2)} USD`
      },
      key:"design_price_sum"
    },
   
    {
      title:"Toplam Sipariş Adedi (OrderItem)",
      render:(record)=>{
        return `${record?.order_item_count_sum}`
      },
      key:"order_item_count_sum"
    }, 
    {
      title:"Tedarikçi Sipariş Adedi",
      render:(record)=>{
        return `${record?.supplier_order_count}`
      },
      key:"supplier_order_count"
    },
  
    {
      title:"Rexven Kar",
      render:(record)=>{
        return `${Number(record?.profit_sum).toFixed(2)} USD`
      },
      key:"profit_sum"
    },
  ]
  subscriptionsColumns: ColumnsType<Subscriptions> = [
    {
      title: "USER EMAIL",
      dataIndex: ["user", "email"],
      key: "email",
      width: "15%",
    },
    {
      title: "NAME",
      render: (record) => {
        return `${record.user.firstName} ${record.user.lastName}`;
      },
      key: "firstName",
      width: "10%",
    },
    {
      title: "START DATE",
      render: (record) => {
        return new Date(record?.startDate)?.toLocaleString("tr-TR");
      },
      key: "startDate",
      width: "10%",
    },
    {
      title: "CANCEL DATE",
      render: (record) => {
        if (record.cancelDate) {
          return new Date(record?.cancelDate)?.toLocaleString("tr-TR");
        } else if (!record.cancelDate && record.isSubscription === true) {
          return (
            <Button id={record.id} danger>
              <span id={record.id}>CANCEL</span>
            </Button>
          );
        }
      },
      key: "cancelDate",
      width: "10%",
    },
    {
      title: "END DATE",
      render: (record) => {
        return record.endDate
          ? new Date(record?.endDate)?.toLocaleString("tr-TR")
          : "";
      },
      key: "endDate",
      width: "10%",
    },
    {
      title: "SUBSCRIBE",
      render: (record) => {
        return record.isSubscription === true ? (
          <Tag color="#87d068">SUBSC</Tag>
        ) : (
          <Tag color="#2db7f5"> PRODS</Tag>
        );
      },
      key: "isSubscription",
      width: "7%",
    },
    {
      title: "DISCORD ID",
      render: (record) => {
        return <Tag color="#87d068">{record.discordUserId}</Tag>;
      },
      key: "discordUserId",
      width: "11%",
    },
    {
      title: "DISCORD LINK",
      render: (record) => {
        if (record?.discordInviteLink)
          return (
            <>
              <Tag color="#87d068">{record.discordInviteLink}</Tag>
              <CopyOutlined id={record.discordInviteLink} />
            </>
          );
      },
      key: "discordInviteLink",
      width: "8%",
    },
    // {
    //   title: "DISCORD ROLES",
    //   dataIndex: "discordRoles",
    //   key: "discordRoles",
    //   width: "7%",
    // },
    {
      title: "remarks",
      render: (record) => {
        return record.remarks !== "none" ? (
          <Tooltip color="volcano" title={record?.remarks}>
            <Tag color={"#f50"}>{record.remarks.slice(0, 25)}</Tag>{" "}
          </Tooltip>
        ) : (
          ""
        );
      },
      key: "remarks",
      width: "11%",
    },
    // {
    //   title: "createdAt",
    //   render: (record) => {
    //     return (
    //       <Tag color="#87d068">{`${record?.createdAt?.slice(
    //         0,
    //         10
    //       )} ${record?.createdAt?.slice(11, 16)}`}</Tag>
    //     );
    //   },
    //   key: "createdAt",
    // },
    {
      title: "PLAN",
      render: (record) => {
        return record?.packagePlan?.duration === 1 ? (
          <Tag color="#f50">{record?.packagePlan?.package?.name} AYLIK</Tag>
        ) : (
          <Tag color="#87d068">{record?.packagePlan?.package?.name} YILIK</Tag>
        );
      },
      width: "12%",
    },
  ];

  invoicesColumns: ColumnsType<Invoices> = [
    {
      title: "OWNER",
      children: [
        {
          title: "EMAIL",
          dataIndex: ["owner", "email"],
          key: "email",
          width: "13%",
        },
        {
          title: "NAME",
          render: (record) => {
            return `${record?.owner?.firstName} ${record?.owner?.lastName}`;
          },
          key: "firstName",
          width: "8%",
        },
      ],
    },
    {
      title: "TYPE",
      render: (record) => {
        return record.type === "course" ? (
          <Tag color="#87d068"> {record.type}</Tag>
        ) : (
          record.type
        );
      },
      key: "type",
      width: "5%",
    },
    {
      title: "BILLING DATE",
      render: (record) => {
        return new Date(record?.billingDate)?.toLocaleString("tr-TR");
      },
      key: "billingDate",
      width: "7%",
    },
    {
      title: "PAYMENT",
      render: (record) => {
        if (record.paymentStatus === "SUCCESS") {
          return <Tag color="#87d068"> {record.paymentStatus}</Tag>;
        } else if (record.paymentStatus === "WAITING") {
          return <Tag color="#2db7f5"> {record.paymentStatus}</Tag>;
        } else {
          return <Tag color="#ff4d4f"> {record.paymentStatus}</Tag>;
        }
      },
      key: "paymentStatus",
      width: "6%",
    },
    {
      title: "RETRY",
      render: (record) => {
        if (record.paymentStatus === "UNPAID" && !record.isCanceled) {
          return (
            <Button danger id={record.id} color="#87d068">
              <span id={record.id}>RETRY</span>
            </Button>
          );
        }
      },
      width: "7%",
    },
    {
      title: "PRICE",
      render: (record) => {
        return `${record.paidPrice} ${record.currency} `;
      },
      key: "paidPrice",
      width: "7%",
    },
    {
      title: "SUBSCRIBE",
      render: (record) => {
        return record.isSubscription === true ? (
          <Tag color="#87d068">SUBSC</Tag>
        ) : (
          <Tag color="#2db7f5"> PRODS</Tag>
        );
      },
      key: "isSubscription",
      width: "5%",
    },
    {
      title: "INVOICE PDF",
      render: (record) => {
        return (
          <>
            <Tooltip color="volcano" title={record?.invoicePdf}>
              <a href={record.invoicePdf} target="_blank" rel="noreferrer">
                {record?.invoicePdf?.slice(0, 30)}...
              </a>
            </Tooltip>
          </>
        );
      },
      key: "invoicePdf",
      width: "13%",
    },
    {
      title: "PLATFORM",
      // dataIndex: "platform",
      render: (record) => {
        return record.platform === "iyzico" ? (
          <Tag color="#f50"> {record.platform}</Tag>
        ) : (
          record.platform
        );
      },
      key: "platform",
      width: "5%",
    },
    {
      title: "CREATED",
      render: (record) => {
        return new Date(record?.createdAt)?.toLocaleString("tr-TR");
      },
      key: "createdAt",
      width: "7%",
    },
    {
      title: "SELLER",
      dataIndex: ["seller", "email"],
      key: "seller",
      width: "10%",
    },
    {
      title: "PLAN",
      render: (record) => {
        return record?.packagePlan?.duration === 1 ? (
          <Tag color="#f50">{record?.packagePlan?.package?.name} AYLIK</Tag>
        ) : (
          <Tag color="#87d068">{record?.packagePlan?.package?.name} YILIK</Tag>
        );
      },
      width: "10%",
    },
    {
      title: "course",
      dataIndex: ["course", "name"],
      key: "course",
      width: "10%",
    },
    {
      title: "Fatura Talebi",
      // dataIndex: ["requestedInvoice", "status"],
      key: "status",
      render: (record) => {
        return record?.requestedInvoice?.status === "WAITING" ? (
          <Button
            color="#f50"
            onClick={() =>
              adminService
                .updateInvoiceStatus(record.id)
                .then((res) => console.log("başarılı"))
                .catch((e) => console.log(e))
            }
          >
            TALEP VAR
          </Button>
        ) : (
          <Tag color="#87d068">GÖNDERİLDİ</Tag>
        );
      },
      width: "10%",
    },
  ];

  unpaidColumns: ColumnsType<any> = [
    {
      title: "EMAIL",
      dataIndex: "email",
      key: "email",
      width: "3%",
    },
    {
      title: "GSM NUMBER",
      dataIndex: "gsmNumner",
      key: "gsmNumner",
      width: "2%",
    },
    {
      title: "BILLING DATE",
      render: (record) => {
        return new Date(record?.billingDate)?.toLocaleString("tr-TR");
      },
      key: "billingDate",
      width: "3%",
    },
    {
      title: "STATUS",
      dataIndex: "payment_status",
      key: "payment_status",
      width: "2%",
    },
    {
      title: "PACKAGE",
      dataIndex: "package",
      key: "package",
      width: "3%",
    },
  ];

  errorLogsColumns: ColumnsType<ErrorLog> = [
    {
      title: "SIDE",
      dataIndex: "side",
      key: "side",
      width: "5%",
    },
    {
      title: "STAT CODE",
      dataIndex: "statusCode",
      key: "statusCode",
      width: "5%",
    },
    {
      title: "LOCATION",
      dataIndex: "location",
      key: "location",
      width: "15%",
    },
    {
      title: "NAME",
      dataIndex: "name",
      key: "name",
      width: "5%",
    },
    {
      title: "MESSAGE",
      dataIndex: "message",
      key: "message",
      width: "20%",
    },
    {
      title: "STACK",
      render: (record) => {
        return (
          <>
            <Tooltip color="volcano" title={record?.stack}>
              <span>{record?.stack?.slice(0, 30)}...</span>
            </Tooltip>
          </>
        );
      },
      key: "stack",
      width: "15%",
    },
    {
      title: "CREATED",
      render: (record) => {
        return new Date(record?.createdAt)?.toLocaleString("tr-TR");
      },
      key: "createdAt",
      width: "12%",
    },
    {
      title: "USER",
      dataIndex: ["user", "email"],
      key: "email",
      width: "10%",
    },
  ];

  coursesColumns: ColumnsType<Courses> = [
    {
      title: "id",
      key: "id",
      width: "18%",
      render: (record) => {
        return record.id;
      },
    },
    {
      title: "Course Name",
      key: "courseName",
      width: "16%",
      render: (record) => {
        return record.courseName;
      },
    },
    {
      title: "Course Location",
      key: "courseLocation",
      width: "17%",
      render: (record) => {
        return record.courseLocation;
      },
    },
    {
      title: "description",
      render: (record) => {
        return record.description;
      },
      key: "description",
      width: "16%",
    },
    {
      title: "isFree",
      render: (record) => {
        return record.isFree ? (
          <Tag color="#87d068">YES</Tag>
        ) : (
          <Tag color="#f50">NO</Tag>
        );
      },
      key: "isFree",
      width: "16%",
    },
    {
      title: "created",
      render: (record) => {
        return record.createdAt;
      },
      key: "createdAt",
      width: "16%",
    },
    {
      title: "updated",
      render: (record) => {
        return record.updatedAt;
      },
      key: "updatedAt",
      width: "16%",
    },
  ];

  userCoursesColumns: ColumnsType<Courses> = [
    {
      title: "USER EMAIL",
      dataIndex: ["user", "email"],
      key: "email",
    },
    {
      title: "USER NAME",
      render: (record) => {
        return `${record.user.firstName} ${record.user.lastName}`;
      },
      key: "firstName",
    },
    {
      title: "COURSE NAME",
      dataIndex: ["course", "name"],
      key: "name",
    },
    {
      title: "PRICE",
      render: (record) => {
        return `${record.course.price} ${record.course.currency}`;
      },
      key: "price",
      width: "16%",
    },
    {
      title: "REMARKS",
      dataIndex: "remarks",
      key: "remarks",
      width: "17%",
    },
    {
      title: "CREATED",
      render: (record) => {
        return new Date(record?.createdAt)?.toLocaleString("tr-TR");
      },
      key: "createdAt",
      width: "16%",
    },
  ];

  couponsColumns: ColumnsType<Coupons> = [
    {
      title: "code",
      render: (name, record) => {
        return <Tag color="#87d068">{record.code}</Tag>;
      },
      key: "code",
      width: "7%",
    },
    {
      title: "platform",
      render: (record) => {
        return record.platform === "iyzico" ? (
          <Tag color="#f50">{record.platform}</Tag>
        ) : (
          record.platform
        );
      },
      key: "platform",
      width: "7%",
    },
    {
      title: "amountOff",
      dataIndex: "amountOff",
      key: "amountOff",
      width: "6%",
    },
    {
      title: "percentOff",
      dataIndex: ["percentOff"],
      key: "percentOff",
      width: "6%",
    },
    {
      title: "currency",
      dataIndex: "currency",
      key: "currency",
      width: "5%",
    },
    {
      title: "duration",
      dataIndex: "duration",
      key: "duration",
      width: "5%",
    },
    {
      title: "durationInMonths",
      dataIndex: "durationInMonths",
      key: "durationInMonths",
      width: "8%",
    },
    {
      title: "maxRedemptions",
      dataIndex: "maxRedemptions",
      key: "maxRedemptions",
      width: "8%",
    },
    {
      title: "redeemedBy",
      dataIndex: "redeemedBy",
      key: "redeemedBy",
      width: "7%",
    },
    {
      title: "timesRedeemed",
      dataIndex: "timesRedeemed",
      key: "timesRedeemed",
      width: "8%",
    },
    {
      title: "valid",
      key: "valid",
      render: (record) => {
        return record.valid === true ? (
          <Tag color="#87d068"> {"YES"}</Tag>
        ) : (
          <Tag color="#f50"> {"NO"}</Tag>
        );
      },
      width: "5%",
    },
    {
      title: "CREATED",
      key: "createdAt",
      render: (record) => {
        return new Date(record?.createdAt)?.toLocaleString("tr-TR");
      },
      width: "9%",
    },
    {
      title: "creator",
      dataIndex: ["creator", "email"],
      key: "email",
      width: "14%",
    },
    {
      title: "affiliateId",
      key: "affiliateId",
      width: "10%",
      render: async (record) => {
        try {
          return record.affiliateId ? (
            <Tag>{record.affiliateId}</Tag>
          ) : (
            <Tag>-</Tag>
          );
        } catch (err) {
          // console.log(err)
        }
      },
    },
    {
      title: "ACTIONS",
      render: (record) => {
        if (record.valid !== false) {
          return (
            <Button id={record.id} danger>
              <span id={record.id}>DELETE</span>
            </Button>
          );
        }
      },

      key: "actions",
      width: "5%",
    },
  ];

  transactionsColumns: ColumnsType<Transactions> = [
    {
      title: "OWNER",
      children: [
        {
          title: "EMAIL",
          dataIndex: ["user", "email"],
          key: "email",
          width: "13%",
        },
        {
          title: "NAME",
          render: (record) => {
            return `${record?.user?.firstName} ${record?.user?.lastName}`;
          },
          key: "firstName",
          width: "8%",
        },
      ],
    },
    {
      title: "PAYMENT INTENT ID",
      dataIndex: "paymentIntentId",
      key: "paymentIntentId",
      width: "11%",
    },
    {
      title: "CHARGE ID",
      dataIndex: "chargeId",
      key: "chargeId",
      width: "11%",
    },
    {
      title: "STATUS",
      render: (record) => {
        if (record.status === "SUCCESS") {
          return <Tag color="#87d068"> {record.status}</Tag>;
        } else if (record.status === "WAITING") {
          return <Tag color="#2db7f5"> {record.status}</Tag>;
        }
      },
      key: "paymentStatus",
      width: "5%",
    },
    {
      title: "AMOUNT",
      render: (record) => {
        return `${record.amount} ${record.currency}`;
      },
      key: "name",
      width: "5%",
    },
    {
      title: "RECEIPT URL",
      render: (record) => {
        return (
          <>
            <Tooltip color="volcano" title={record?.receiptUrl}>
              <a href={record.receiptUrl} target="_blank" rel="noreferrer">
                {record?.receiptUrl?.slice(0, 30)}...
              </a>
            </Tooltip>
          </>
        );
      },
      key: "invoicePdf",
      width: "11%",
    },
    {
      title: "ERROR TYPE / CODE /MESSAGE",
      render: (record) => {
        return (
          record?.errorMessage?.length && (
            <>
              <p>{record?.errorType}</p>
              <p>{record?.errorCode}</p>
              <Tooltip color="volcano" title={record?.errorMessage}>
                <Tag color={"#f50"}>{record.errorMessage.slice(0, 25)}</Tag>
              </Tooltip>
            </>
          )
        );
      },
      width: "9%",
    },
    {
      title: "COMPLETED",
      render: (record) => {
        return record?.isCompleted === true ? (
          <Tag color="#87d068">YES</Tag>
        ) : (
          <Tag color="#f50">NO</Tag>
        );
      },
      key: "isSeller",
      width: "7%",
    },
    {
      title: "REFUND",
      render: (record) => {
        if (record?.isCompleted === true && record?.isRefunded === false) {
          return (
            <Button danger id={record?.invoiceId} color="#f50">
              <CloseOutlined id={record?.invoiceId} />
              <span id={record?.invoiceId}> REFUND</span>
            </Button>
          );
        } else if (
          record?.isCompleted === true &&
          record?.isRefunded === true
        ) {
          return (
            <Tag color="#87d068">
              <CheckOutlined />
              REFUNDED
            </Tag>
          );
        }
      },
      key: "isSeller",
      width: "7%",
    },
    {
      title: "CREATED",
      render: (record) => {
        return new Date(record?.createdAt)?.toLocaleString("tr-TR");
      },
      key: "createdAt",
    },
    {
      title: "INVOICE ID",
      dataIndex: "invoiceId",
      key: "invoiceId",
      width: "5%",
    },
    {
      title: "AFFILIATE",
      key: "affiliate",
      dataIndex: "affiliate",
      width: "5%",
    },
  ];

  packagesColumns: ColumnsType<PackagePlans> = [
    {
      title: "id",
      render: (record) => {
        return `${record.id}`;
      },
      key: "id",
    },
    {
      title: "Package Name",
      render: (record) => {
        return `${record.package.name}`;
      },
      key: "package",
    },
    {
      title: "Platform",
      render: (record) => {
        return `${record.platform}`;
      },
      key: "platform",
    },
    {
      title: "duration",
      render: (record) => {
        if (record.duration === 1) {
          return <Tag color="#87d068">Aylık</Tag>;
        } else if (record.duration === 12) {
          return <Tag color="#2db7f5">Yıllık</Tag>;
        }
      },
      key: "duration",
      width: "16%",
    },
    {
      title: "discount",
      render: (record) => {
        return `${record.discount ? "%" + record.discount : null}`;
      },
      key: "discount",
      width: "16%",
    },
    {
      title: "price",
      render: (record) => {
        return `${record.price}`;
      },
      key: "price",
      width: "16%",
    },
    {
      title: "Plan Reference",
      key: "planReference",
      render: (record) => {
        return `${record.planReference}`;
      },
      width: "17%",
    },
  ];

  announcementsColumns: ColumnsType<IAnnouncements> = [
    {
      title: "id",
      dataIndex: "id",
      key: "id",
      width: "2%",
    },
    {
      title: "type",
      render: (record) => {
        return record.type === "rexven" ? (
          <Tag color="#f50">{record.type}</Tag>
        ) : (
          <Tag color="rgb(135, 208, 104)">{record.type}</Tag>
        );
      },
      key: "type",
      width: "5%",
    },
    {
      title: "image",
      // dataIndex: "image",
      key: "image",
      width: "8%",
      render: (record) => (
        <img
          width={50}
          src={`${API_IMAGE_URL}/${record.image}`}
          alt={record.image}
        />
      ),
    },
    {
      title: "title",
      dataIndex: "title",
      key: "title",
      width: "10%",
    },
    {
      title: "description",
      dataIndex: "description",
      key: "description",
      width: "20%",
    },
    {
      title: "creator",
      dataIndex: ["creator", "email"],
      key: "email",
      width: "8%",
    },
    {
      title: "createdAt",
      dataIndex: "createdAt",
      key: "createdAt",
      width: "8%",
    },
    {
      title: "ACTIONS",
      render: (record) => (
        <Button id={record.id} danger>
          <span id={record.id}>UPDATE</span>
        </Button>
      ),

      key: "actions",
      width: "4%",
    },
  ];
}

export default new columnsList();

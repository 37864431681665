import axios, { AxiosResponse } from "axios";

import { IDeloitteAmazonCategoryCreateFormValues } from "../components/deloitteBsr/addCategoryForm";
import { API_ADMIN_URL, API_REXVEN_URL, PAYMENT_URL } from "../config";
import {
  CouponsRootObject,
  CoursesRootObject,
  CreateCoupon,
  CreateCouponRootObject,
  CreateProvidersRootObject,
  DeloitteCountry,
  ErrorLogsRootObject,
  ExclusiveProduct,
  FaqCategoryRootObject,
  IAddCourseValues,
  IAnnouncementsValues,
  ICreateAnnouncementValues,
  IDeloitteAmazonCategory,
  IDeloitteAmazonCategoryBsrIntervalsResponse,
  IDeloitteAmazonCategoryResponse,
  InvoicesRootObject,
  IService,
  ProviderRootObject,
  ProvidersDeleteRootObject,
  ProvidersRootObject,
  Question,
  QuestionRootObject,
  RootGiftedUser,
  SubscriptionsRootObject,
  TransactionsRootObject,
  UpdateProviderRootObject,
  UpdateUserPasswordRootObject,
  UpdateUserRoleRootObject,
  UserCoursesRootObject,
  UserDetailRootObject,
  UserRootObject,
  WholeContentRootObject,
} from "../interfaces/adminInterfaces";
import { IUpdateCategoryFormValues } from "../pages/DeloitteAmazonBsr";
import { IDeloitteCreateBsrObject } from "../pages/DeloitteBsrDetail";
import httpClient from "../utils/httpClient";

class AdminService {
  responseBody = (response: AxiosResponse) => response.data;

  // define default setup of axios CRUD operations
  requests = {
    get: (url: string) => httpClient.get(url).then(this.responseBody),
    post: (url: string, body: {}, headers?: any) =>
      httpClient.post(url, body, headers).then(this.responseBody),
    put: (url: string, body: {}) =>
      httpClient.put(url, body).then(this.responseBody),
    patch: (url: string, body?: {}) =>
      httpClient.patch(url, body).then(this.responseBody),
    delete: (url: string, body?: {}) =>
      httpClient.delete(url, body).then(this.responseBody),
  };

  //get Users
  public getUsers(
    page: number = 1,
    limit: number = 10,
    q: string = ""
  ): Promise<UserRootObject> {
    return this.requests.get(
      `${API_ADMIN_URL}/users?page=${page}&limit=${limit}&query=${q}`
    );
  }

  // get User Details
  public getUserDetails(id?: string): Promise<UserDetailRootObject> {
    return this.requests.get(`${API_ADMIN_URL}/users/${id}`);
  }

  //update UserRole
  public updateUserRole(
    id: number,
    data: { roleId: number }
  ): Promise<UpdateUserRoleRootObject> {
    return this.requests.patch(`${API_ADMIN_URL}/users/${id}/roles`, data);
  }

  //update UserPassword
  public updateUserPassword(id: number): Promise<UpdateUserPasswordRootObject> {
    return this.requests.patch(`${API_ADMIN_URL}/users/${id}/password`);
  }

  //SUBSCRIPTIONS
  //  get subscriptions
  public getSubscriptions(
    page: number = 1,
    limit: number = 10,
    q: string = ""
  ): Promise<SubscriptionsRootObject> {
    return this.requests.get(
      `${API_ADMIN_URL}/subscriptions?page=${page}&limit=${limit}&query=${q}`
    );
  }

  //delete subscription
  public cancelSubscription(id: number) {
    return this.requests.patch(`${API_ADMIN_URL}/subscriptions/${id}`);
  }

  //  get courses
  public getCourses(
    page: number = 1,
    limit: number = 10,
    q: string = ""
  ): Promise<CoursesRootObject> {
    return this.requests.get(
      `${API_ADMIN_URL}/courses?page=${page}&limit=${limit}&query=${q}`
    );
  }

  public addCourse(data: any) {
    return this.requests.post(`${API_ADMIN_URL}/courses`, data);
  }

  // add Section
  public addSection(
    courseId: number,
    data: {
      sectionLocation: string;
      sectionName: string;
      sectionOrder: number;
    }
  ) {
    return this.requests.post(
      `${API_ADMIN_URL}/courses/${courseId}/sections`,
      data
    );
  }

  //add content
  public async addContent(
    sectionId: number,
    path: string,
    data: any
  ): Promise<any> {
    return this.requests.post(
      `${API_ADMIN_URL}/sections/${sectionId}/contents?path=${path}`,
      data
    );
  }

  //delete content
  public deleteContent(contentId: number) {
    return this.requests.delete(`${API_ADMIN_URL}/contents/${contentId}`);
  }

  // get contents and sections of the course
  public getWholeContentByCourseId(
    courseId: number
  ): Promise<WholeContentRootObject> {
    return this.requests.get(`${API_ADMIN_URL}/courses/${courseId}`);
  }

  // update content note
  public updateContentNote(contentId: number, data: { notes: string }) {
    return this.requests.patch(`${API_ADMIN_URL}/contents/${contentId}`, data);
  }
  public updateContentName(contentId: number, data: { contentName: string }) {
    return this.requests.patch(`${API_ADMIN_URL}/contents/${contentId}`, data);
  }

  //  get user courses
  public getUserCourses(
    page: number = 1,
    limit: number = 10,
    q: string = ""
  ): Promise<UserCoursesRootObject> {
    return this.requests.get(
      `${API_ADMIN_URL}/user-courses?page=${page}&limit=${limit}&query=${q}`
    );
  }

  //  get error-logs
  public getErrorLogs(
    page: number = 1,
    limit: number = 10,
    q: string = ""
  ): Promise<ErrorLogsRootObject> {
    return this.requests.get(
      `${API_ADMIN_URL}/errors?page=${page}&limit=${limit}&query=${q}`
    );
  }

  //COUPONS
  //  get coupons
  public getCoupons(
    page: number = 1,
    limit: number = 10,
    q: string = ""
  ): Promise<CouponsRootObject> {
    return this.requests.get(
      `${API_ADMIN_URL}/coupons?page=${page}&limit=${limit}&query=${q}`
    );
  }

  // create COUPON
  public createCoupon(data: CreateCoupon): Promise<CreateCouponRootObject> {
    return this.requests.post(`${API_ADMIN_URL}/coupons`, data);
  }

  //delete Coupon
  public deleteCoupon(id: number) {
    return this.requests.patch(`${API_ADMIN_URL}/coupons/${id}`);
  }

  //  get invoices
  public getInvoices(
    page: number = 1,
    limit: number = 10,
    q: string = "",
    paymentStatus: string = ""
  ): Promise<InvoicesRootObject> {
    return this.requests.get(
      `${API_ADMIN_URL}/invoices?page=${page}&limit=${limit}&query=${q}&paymentStatus=${paymentStatus}`
    );
  }

  //  get invoices
  public getUnPaids(
    page: number = 1,
    limit: number = 10,
    q: string = ""
  ): Promise<InvoicesRootObject> {
    return this.requests.get(
      `${API_ADMIN_URL}/expired-payments?page=${page}&limit=${limit}&query=${q}`
    );
  }

  //  get transactions
  public getTransactions(
    page: number = 1,
    limit: number = 10,
    q: string = ""
  ): Promise<TransactionsRootObject> {
    return this.requests.get(
      `${API_ADMIN_URL}/transactions?page=${page}&limit=${limit}&query=${q}`
    );
  }

  //refund Transaction
  public refundTransaction(id: number) {
    return this.requests.patch(`${API_ADMIN_URL}/transactions/refund/${id}`);
  }

  public retryPayment(invoiceId: number) {
    return this.requests.post(
      `${API_ADMIN_URL}/invoices/retry/${invoiceId}`,
      {}
    );
  }

  public updateInvoiceStatus(invoiceId: number) {
    return this.requests.patch(`${API_ADMIN_URL}/invoices/${invoiceId}`, {
      status: "SENT",
    });
  }

  // get packages
  public getPackagePlans() {
    return this.requests.get(`${API_ADMIN_URL}/package-plans`);
  }

  // get affiliates
  public getAffiliates() {
    return this.requests.get(`${API_ADMIN_URL}/affiliates`);
  }

  //COUPONS
  //  get coupons
  public getCouposns(
    page: number = 1,
    limit: number = 10,
    q: string = ""
  ): Promise<CouponsRootObject> {
    return this.requests.get(
      `${API_ADMIN_URL}/coupons?page=${page}&limit=${limit}&query=${q}`
    );
  }

  //SERVICE PROVIDERS

  // create service providers
  public async createServiceProviders(
    data: any
  ): Promise<CreateProvidersRootObject> {
    return this.requests.post(`${API_ADMIN_URL}/service-providers`, data);
  }

  // get providers
  public async getProviders(query: string): Promise<ProvidersRootObject> {
    return this.requests.get(`${API_ADMIN_URL}/service-providers?${query}`);
  }

  public async deleteProviders(
    id: number | string
  ): Promise<ProvidersDeleteRootObject> {
    return this.requests.delete(`${API_ADMIN_URL}/service-providers/${id}`);
  }

  public async deleteServiceFromProvider(
    serviceId: number | string,
    spId: number | string
  ): Promise<void> {
    return this.requests.delete(
      `${API_ADMIN_URL}/service-providers/${spId}/services/${serviceId}`
    );
  }

  public async getProvider(id?: number | string): Promise<ProviderRootObject> {
    return this.requests.get(`${API_ADMIN_URL}/service-providers/${id}`);
  }
  public async updateProvider(
    id: number,
    data: any
  ): Promise<UpdateProviderRootObject> {
    return this.requests.patch(
      `${API_ADMIN_URL}/service-providers/${id}`,
      data
    );
  }

  //  ----- FAQ ----------
  public async getAllFaq(
    page: number,
    keyword: string
  ): Promise<QuestionRootObject> {
    return this.requests.get(
      `${API_ADMIN_URL}/frequently-asked-questions?page=${page}&keyword=${keyword}`
    );
  }
  public async getQuestionById(
    id: string
  ): Promise<{ status: string; data: Question }> {
    return this.requests.get(
      `${API_ADMIN_URL}/frequently-asked-questions/${id}`
    );
  }
  public async editQuestion(
    id: number,
    payload: { answer: string; question: string }
  ): Promise<{ status: string; data: Question }> {
    return this.requests.patch(
      `${API_ADMIN_URL}/frequently-asked-questions/${id}`,
      payload
    );
  }
  public async deleteFaq(id: number): Promise<QuestionRootObject> {
    return this.requests.delete(
      `${API_ADMIN_URL}/frequently-asked-questions/${id}`
    );
  }
  public async createFaq(payload: any): Promise<QuestionRootObject> {
    return this.requests.post(
      `${API_ADMIN_URL}/frequently-asked-questions`,
      payload
    );
  }
  public async getFaqCategories(): Promise<FaqCategoryRootObject> {
    return this.requests.get(`${API_ADMIN_URL}/faq-category`);
  }
  public async createFaqCategory(payload: any): Promise<{ status: string }> {
    return this.requests.post(`${API_ADMIN_URL}/faq-category`, payload);
  }
  public async getGiftMembers(
    page: number,
    email: string
  ): Promise<RootGiftedUser> {
    return this.requests.get(
      `${API_ADMIN_URL}/gift-subscriptions?page=${page}&email=${email}`
    );
  }
  public async cancelGift(id: number): Promise<{ status: string }> {
    return this.requests.patch(`${API_ADMIN_URL}/gift-subscriptions/${id}`);
  }
  public async updateGift(
    id: number,
    payload: { packageName: string }
  ): Promise<{ status: string }> {
    return this.requests.patch(
      `${API_ADMIN_URL}/gift-subscriptions/package-name/${id}`,
      payload
    );
  }

  public async createGift(payload: {
    email: string;
    courseIds?: number[];
    isLifeTime?: boolean;
    startDate?: Date;
    endDate?: Date;
  }): Promise<{}> {
    return this.requests.post(`${API_ADMIN_URL}/gift-subscriptions/`, payload);
  }
  public async createExclusiveProduct(
    payload: any
  ): Promise<{ status: string }> {
    return this.requests.post(`${API_ADMIN_URL}/exclusive/products`, payload);
  }
  public async editExclusiveProduct(
    id: number,
    payload: {
      asin: string;
      leadTime: number;
      shippingCost: number;
      productCost: number;
      maxOrderQuantity: number;
    }
  ): Promise<{ status: string; data: ExclusiveProduct }> {
    return this.requests.patch(
      `${API_ADMIN_URL}/exclusive/products/${id}`,
      payload
    );
  }
  public async getExclusiveProductById(
    id: string
  ): Promise<{ status: string; data: ExclusiveProduct }> {
    return this.requests.get(`${API_ADMIN_URL}/exclusive/products/${id}`);
  }
  public async deleteExcProduct(id: number): Promise<QuestionRootObject> {
    return this.requests.delete(`${API_ADMIN_URL}/exclusive/products/${id}`);
  }
  public async getExcProducts(
    page: number,
    asin: string
  ): Promise<{
    status: string;
    data: { totalPageCount: number; data: ExclusiveProduct[] };
  }> {
    return this.requests.get(
      `${API_ADMIN_URL}/exclusive/products?page=${page}&asin=${asin}`
    );
  }

  public async createServiceProviderSub(
    data: any
  ): Promise<CreateProvidersRootObject> {
    return this.requests.post(
      `${API_ADMIN_URL}/service-provider-subscriptions`,
      data
    );
  }
  public async getServiceProviderSub(id: number): Promise<any> {
    return this.requests.get(
      `${API_ADMIN_URL}/service-provider-subscriptions/${id}`
    );
  }
  public async updateServiceProviderSub(
    data: any
  ): Promise<UpdateProviderRootObject> {
    return this.requests.patch(
      `${API_ADMIN_URL}/service-provider-subscriptions/${data.id}`,
      data
    );
  }
  public async deleteServiceProviderSub(
    id: number | string
  ): Promise<ProvidersDeleteRootObject> {
    return this.requests.delete(
      `${API_ADMIN_URL}/service-provider-subscriptions/${id}`
    );
  }
  public async getServiceProviderSubs(): Promise<ProvidersRootObject> {
    return this.requests.get(`${API_ADMIN_URL}/service-provider-subscriptions`);
  }

  public async getPlans(): Promise<ProvidersRootObject> {
    return this.requests.get(`${PAYMENT_URL}/api/v1/userActions/iyzico-plan`);
  }
  public async createPlan(payload: any): Promise<ProvidersRootObject> {
    return this.requests.post(
      `${PAYMENT_URL}/api/v1/userActions/iyzico-plan`,
      payload
    );
  }
  //BIRFATURA REQUESTS
  public async getBirFaturaInvoices(payload: {
    startDate: string;
    endDate: string;
    page: number;
    email: string;
  }): Promise<any> {
    return this.requests.get(
      `${API_ADMIN_URL}/birfatura-invoices?startDate=${payload.startDate}&endDate=${payload.endDate}&email=${payload.email}&page=${payload.page}`
    );
  }
  public async deleteBirFaturaInvoice(id: number): Promise<any> {
    return this.requests.delete(`${API_ADMIN_URL}/birfatura-invoices/${id}`);
  }
  public async getUsersBillingInfo(email: string): Promise<any> {
    return this.requests.get(
      `${API_ADMIN_URL}/birfatura-invoices/billing-info?email=${email}`
    );
  }
  public async getPaymentMethhods(): Promise<any> {
    return this.requests.get(
      `${API_ADMIN_URL}/birfatura-invoices/payment-methods`
    );
  }
  public async getBirFaturaPackages(): Promise<any> {
    return this.requests.get(`${API_ADMIN_URL}/birfatura-invoices/packages`);
  }
  public async createBirFaturaInvoice(payload: any): Promise<any> {
    return this.requests.post(`${API_ADMIN_URL}/birfatura-invoices`, payload);
  }
  public async updateBirFaturaInvoice(id: string, payload: any): Promise<any> {
    return this.requests.patch(
      `${API_ADMIN_URL}/birfatura-invoices/${id}`,
      payload
    );
  }
  public async createSubsPayment(payload: any): Promise<any> {
    return this.requests.post(`${API_ADMIN_URL}/sp-payment`, payload);
  }
  public async getSubsPayments(subsId: number): Promise<any> {
    return this.requests.get(`${API_ADMIN_URL}/sp-payment/${subsId}`);
  }
  public async updateSubsPayment(id: number, payload: any): Promise<any> {
    return this.requests.patch(`${API_ADMIN_URL}/sp-payment/${id}`, payload);
  }
  public async deleteSubsPayment(id: number): Promise<any> {
    return this.requests.delete(`${API_ADMIN_URL}/sp-payment/${id}`);
  }
  public async createBill(id: number): Promise<any> {
    return this.requests.post(`${API_ADMIN_URL}/sp-payment/birfatura`, {
      id: id,
    });
  }
  //AMZ BSR CATEGORIES
  public async getAmazonCategories(query?: string): Promise<any> {
    return this.requests.get(
      `${API_ADMIN_URL}/bsr/categories?country=${query}`
    );
  }
  public async getAmazonCategoryById(id: number): Promise<any> {
    return this.requests.get(`${API_ADMIN_URL}/bsr/categories/${id}`);
  }
  public async deleteAmazonCategory(id: number): Promise<any> {
    return this.requests.delete(`${API_ADMIN_URL}/bsr/categories/${id}`);
  }
  public async updateAmazonCategory(id: number, payload: any): Promise<any> {
    return this.requests.patch(
      `${API_ADMIN_URL}/bsr/categories/${id}`,
      payload
    );
  }
  public async createBSRCategory(payload: any): Promise<any> {
    return this.requests.post(`${API_ADMIN_URL}/bsr/categories/`, payload);
  }

  //AMZ BSR RANKS
  public async createRank(payload: any): Promise<any> {
    return this.requests.post(`${API_ADMIN_URL}/bsr/ranks/`, payload);
  }
  public async deleteAmazonCategoryRank(id: number): Promise<any> {
    return this.requests.delete(`${API_ADMIN_URL}/bsr/ranks/${id}`);
  }
  public async updateAmazonCategoryRank(
    id: number,
    payload: any
  ): Promise<any> {
    return this.requests.patch(`${API_ADMIN_URL}/bsr/ranks/${id}`, payload);
  }
  public async startCron(): Promise<any> {
    return this.requests.patch(`${API_ADMIN_URL}/arbitrage/sale-counts`);
  }

  public async upgradeSub(id: number, data: any): Promise<ProvidersRootObject> {
    return this.requests.post(
      `${PAYMENT_URL}/api/v1/userActions/package-plan/${id}`,
      data
    );
  }
  public async getSpInvoices(id: number): Promise<ProvidersRootObject> {
    return this.requests.get(
      `${PAYMENT_URL}/api/v1/userActions/invoices/${id}`
    );
  }

  public async getBlogCategories(lang: string): Promise<any> {
    return this.requests.get(`${API_ADMIN_URL}/blog/categories?lang=${lang}`);
  }

  public async getAuthors(): Promise<any> {
    return this.requests.get(`${API_ADMIN_URL}/blog/authors`);
  }

  public async addAuthor(payload: any): Promise<any> {
    return this.requests.post(`${API_ADMIN_URL}/blog/authors`, payload);
  }

  public async uploadImageToBlog(payload: any): Promise<any> {
    return this.requests.post(`${API_ADMIN_URL}/blog/upload-image`, payload);
  }

  public async addBlogPost(payload: any): Promise<any> {
    return this.requests.post(`${API_ADMIN_URL}/blog`, payload);
  }

  public async getBlogPosts(lang: string, keyword: string): Promise<any> {
    return this.requests.get(
      `${API_ADMIN_URL}/blog?lang=${lang}&keyword=${keyword}`
    );
  }

  public async getBlogPostById(lang: string, id: number): Promise<any> {
    return this.requests.get(`${API_ADMIN_URL}/blog/${lang}/${id}`);
  }

  public async updateBlogPost(
    lang: string,
    id: number,
    payload: any
  ): Promise<any> {
    return this.requests.patch(`${API_ADMIN_URL}/blog/${lang}/${id}`, payload);
  }

  public async deleteBlogPost(lang: string, id: number): Promise<any> {
    return this.requests.delete(`${API_ADMIN_URL}/blog/${lang}/${id}`);
  }

  public async getProducers(
    page: number,
    search: string,
    platform: string
  ): Promise<any> {
    return this.requests.get(
      `${API_ADMIN_URL}/producers?page=${page}&search=${search}&platform=${platform}`
    );
  }

  public async deleteAICat(id: number): Promise<any> {
    return this.requests.delete(`${API_ADMIN_URL}/ai-category/${id}`);
  }
  public async createAICat(payload: any): Promise<any> {
    return this.requests.post(`${API_ADMIN_URL}/ai-category`, payload);
  }
  public async updateAICat(id: number, payload: any): Promise<any> {
    return this.requests.put(`${API_ADMIN_URL}/ai-category/${id}`, payload);
  }
  public async getAICat(id: number): Promise<any> {
    return this.requests.get(`${API_ADMIN_URL}/ai-category/${id}`);
  }
  public async getAICats(level: string): Promise<any> {
    return this.requests.get(`${API_ADMIN_URL}/ai-category?level=${level}`);
  }

  public async createAIData(payload: any): Promise<any> {
    return this.requests.post(`${API_ADMIN_URL}/ai-data`, payload);
  }
  public async updateAIData(id: number, payload: any): Promise<any> {
    return this.requests.put(`${API_ADMIN_URL}/ai-data/${id}`, payload);
  }
  public async getAIData(id: number): Promise<any> {
    return this.requests.get(`${API_ADMIN_URL}/ai-data/${id}`);
  }

  public async getServices(
    page: number,
    approval: "requested" | "declined" | "accepted" | "all"
  ): Promise<any> {
    return this.requests.get(
      `${API_ADMIN_URL}/services?page=${page}&approval=${approval}`
    );
  }

  public async getService(id: number): Promise<{ data: IService }> {
    return this.requests.get(`${API_ADMIN_URL}/services/${id}`);
  }

  public async updateService(
    id: number,
    body: any
  ): Promise<{ data: IService }> {
    return this.requests.patch(`${API_ADMIN_URL}/services/${id}`, body);
  }

  public async deleteService(id: number): Promise<void> {
    return this.requests.delete(`${API_ADMIN_URL}/services/${id}`);
  }

  public async getSpCategories(): Promise<{
    data: { id: number; categoryName: string }[];
  }> {
    return this.requests.get(`${API_ADMIN_URL}/sp-categories`);
  }

  public async addSpService(body: any) {
    return this.requests.post(`${API_ADMIN_URL}/sp-categories`, body);
  }

  public async updateContentOrder(body: {
    contentId: number;
    contentOrder: number;
  }) {
    return this.requests.patch(`${API_ADMIN_URL}/content-order`, body);
  }

  public async getContactFormAnswers(page: number) {
    return this.requests.get(
      `${API_ADMIN_URL}/contact-form-answers?page=${page}`
    );
  }

  public async verifyUser(userId: number) {
    return this.requests.post(`${API_ADMIN_URL}/users/verify/${userId}`, {});
  }

  public async verifyGsmNumber(userId: number) {
    return this.requests.post(
      `${API_ADMIN_URL}/users/gsm-verify/${userId}`,
      {}
    );
  }

  // announcements
  public async getAnnouncements(
    page: number = 1
  ): Promise<IAnnouncementsValues> {
    return this.requests.get(`${API_REXVEN_URL}/announcements?page=${page}`);
  }

  public async createAnnouncement(
    data: any
  ): Promise<ICreateAnnouncementValues> {
    return this.requests.post(`${API_ADMIN_URL}/announcements`, data);
  }

  public async updateEndDateOfRefundedSubscription(
    packagePlanId: number
  ): Promise<void> {
    return this.requests.patch(
      `${API_ADMIN_URL}/package-cancel-date/${packagePlanId}`
    );
  }

  public async createAiQuestion(data: { question: string }): Promise<void> {
    return axios.post(`https://chat-ai.rexven.com/ai-chat/`, data);
  }

  public async getDeloitteAmazonCategories(
    country: DeloitteCountry
  ): Promise<IDeloitteAmazonCategoryResponse> {
    return this.requests.get(
      `${API_ADMIN_URL}/deloitte/amazon/categories?country=${country}`
    );
  }

  public async createDeloitteAmazonCategry(
    formValues: IDeloitteAmazonCategoryCreateFormValues
  ): Promise<void> {
    return this.requests.post(
      `${API_ADMIN_URL}/deloitte/amazon/category`,
      formValues
    );
  }

  public async deleteDeloitteAmazonCategory(categoryId: number): Promise<void> {
    return this.requests.delete(
      `${API_ADMIN_URL}/deloitte/amazon/category/${categoryId}`
    );
  }

  public async updateDeloitteAmazonCategory(
    categoryId: number,
    formValues: IUpdateCategoryFormValues
  ): Promise<void> {
    return this.requests.patch(
      `${API_ADMIN_URL}/deloitte/amazon/category/${categoryId}`,
      formValues
    );
  }

  public async getDeloitteAmazonCategoryBsrIntervals(
    categoryId: number
  ): Promise<IDeloitteAmazonCategoryBsrIntervalsResponse> {
    return this.requests.get(
      `${API_ADMIN_URL}/deloitte/amazon/category/bsr/${categoryId}`
    );
  }

  public async deleteDeloitteBsrInterval(bsrId: number): Promise<void> {
    return this.requests.delete(
      `${API_ADMIN_URL}/deloitte/amazon/category/bsr/${bsrId}`
    );
  }

  public async createDeloitteBsrInterval(
    categoryId: number,
    data: IDeloitteCreateBsrObject
  ): Promise<void> {
    return this.requests.post(
      `${API_ADMIN_URL}/deloitte/amazon/category/${categoryId}/bsr`,
      data
    );
  }

  public async updateDeloitteBsrInterval(
    bsrId: number,
    data: IDeloitteCreateBsrObject
  ): Promise<void> {
    return this.requests.patch(
      `${API_ADMIN_URL}/deloitte/amazon/category/bsr/${bsrId}`,
      data
    );
  }
  // WALLET REQUESTS
  public async withdrawOrDepositWallet(
    id: number,
    payload: { amount: number; type: "withdraw" | "deposit"; info?: string }
  ): Promise<void> {
    return this.requests.post(
      `${API_ADMIN_URL}/marketplace/wallet/transactions/${id}`,
      payload
    );
  }
  public async getWalletRequests(page: number): Promise<any> {
    return this.requests.get(
      `${API_ADMIN_URL}/marketplace/wallet/requests?page=${page}`
    );
  }
  public async getMarketplaceProducts(
    page: number,
    keyword: string
  ): Promise<any> {
    return this.requests.get(
      `${API_ADMIN_URL}/marketplace/supplier-products?page=${page}&keyword=${keyword}`
    );
  }
  public async getMarketplaceProductCategories(): Promise<any> {
    return this.requests.get(`${API_ADMIN_URL}/marketplace/product-categories`);
  }
  public async updateMarketplaceProduct(
    id: number,
    payload: any
  ): Promise<any> {
    return this.requests.patch(
      `${API_ADMIN_URL}/marketplace/supplier-product/${id}`,
      payload
    );
  }
  public async updateMarketplaceProductVariant(
    id: number,
    payload: any
  ): Promise<any> {
    return this.requests.patch(
      `${API_ADMIN_URL}/marketplace/supplier-product-variant/${id}`,
      payload
    );
  }
  public async deleteMarketplaceProductVariant(id: number): Promise<any> {
    return this.requests.delete(
      `${API_ADMIN_URL}/marketplace/supplier-product-variant/${id}`
    );
  }

  public async createVariationProduct(payload: any): Promise<any> {
    return this.requests.post(
      `${API_ADMIN_URL}/marketplace/supplier-product-variant`,
      payload
    );
  }
  public async getSuppliers(): Promise<any> {
    return this.requests.get(`${API_ADMIN_URL}/marketplace/suppliers`);
  }
  public async getSupplierSalesSummary(
    supplierId: number,
    month: number,
    year: number,
    sellerShopId: number
  ): Promise<any> {
    return this.requests.get(
      `${API_ADMIN_URL}/marketplace/sales-summary?supplierId=${supplierId}&month=${month}&year=${year}&sellerShopId=${sellerShopId}`
    );
  }
  public async getSuppliersPaymentInfos(): Promise<any> {
    return this.requests.get(`${API_ADMIN_URL}/marketplace/payments-summary`);
  }
  public async createProduct(payload: any): Promise<any> {
    return this.requests.post(
      `${API_ADMIN_URL}/marketplace/supplier-product`,
      payload
    );
  }

  public async updateStatusOfWalletRequest(
    id: number,
    payload: { status: "pending" | "processed"; adminNote?: string }
  ): Promise<void> {
    return this.requests.patch(
      `${API_ADMIN_URL}/marketplace/wallet/${id}/status`,
      payload
    );
  }

  public async createStore(payload: any): Promise<any> {
    return this.requests.post(
      `https://api.rexven.com/api/v1/integration`,
      payload
    );
  }
  public async getStores(): Promise<any> {
    return this.requests.get(`${API_ADMIN_URL}/shipstation/stores`);
  }
  public async getSellerShops(): Promise<any> {
    return this.requests.get(`${API_ADMIN_URL}/seller/shops`);
  }
  public async getShipentegraStores(data: string): Promise<any> {
    return this.requests.get(
      `https://api.rexven.com/api/v1/admin/shipentegra/stores?userId=${data}`
    );
  }

  public async getBuyboxes(): Promise<any> {
    return this.requests.get(`${API_ADMIN_URL}/buybox-rule`);
  }
  public async createBuybox(payload: any): Promise<any> {
    return this.requests.post(`${API_ADMIN_URL}/buybox-rule`, payload);
  }
  public async updateBuybox(id: number, payload: any): Promise<any> {
    return this.requests.patch(`${API_ADMIN_URL}/buybox-rule/${id}`, payload);
  }
  public async deleteBuybox(id: number): Promise<any> {
    return this.requests.delete(`${API_ADMIN_URL}/buybox-rule/${id}`);
  }
  public async getBuybox(id: number): Promise<any> {
    return this.requests.get(`${API_ADMIN_URL}/buybox-rule/${id}`);
  }
  public async getSupplierProductVariants(search: string): Promise<any> {
    return this.requests.get(
      `https://api.rexven.com/api/v1/marketplace/all-variants?keyword=${search}`
    );
  }
  public async getResendRequests(page: number): Promise<any> {
    return this.requests.get(
      `${API_ADMIN_URL}/marketplace/resend-requests?page=${page}`
    );
  }
  public async updateResendRequest(payload: {
    id: number;
    status: string;
    rejectReason?: string;
  }): Promise<any> {
    return this.requests.patch(
      `${API_ADMIN_URL}/marketplace/resend-requests/${payload.id}`,
      { status: payload.status, rejectReason: payload.rejectReason }
    );
  }
  public async getTargets(): Promise<any> {
    return this.requests.get(`${API_ADMIN_URL}/marketplace/targets`);
  }
}

export default new AdminService();

import React, { useEffect } from "react";
import { Button, Form, Input, InputNumber, Select } from "antd";
import adminService from "../../services/adminService";
import alertNotification from "../../utils/alertNotification";

interface Props {
  product: any;
  getProducts: () => Promise<void>
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>
}
const UpdateVariationForm: React.FC<Props> = ({ product,getProducts,setOpenModal }) => {
  const [form] = Form.useForm();
  //dümenden
  const handleSubmit = async (values: any) => {

    try {
      await adminService.updateMarketplaceProductVariant(product.id, values);
        setOpenModal(false);
      getProducts()
      
    } catch (error:any) {
      alertNotification("error", error.response.data.message);
    }
   

  };

  useEffect(() => {
    form.setFieldsValue(product);
  } , [product])
  
  return (
    <div>
      <Form
        form={form}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18, offset: 1 }}
        onFinish={handleSubmit}
      >
        <Form.Item name={"name"} label="Başlık">
          <Input />
        </Form.Item>
        <Form.Item name={"price"} label="Fiyat">
          <InputNumber />
        </Form.Item>

        <Form.Item
          label="Resimler:"
          name={"imageList"}
        >
          <Input placeholder="Lütfen resim yollarının arasına virgül koyun" />
        </Form.Item>
        <Form.Item
          label="Ağırlık Miktarı"
          name={"weightValue"}
        >
          <InputNumber />
        </Form.Item>
        <Form.Item label="Ağırlık Cinsi" name="weightUnit">
            <Select>
                <Select.Option value="grams">Gram</Select.Option>
                <Select.Option value="pounds">Pound</Select.Option>
                <Select.Option value="pououncesnds">Ounce</Select.Option>
            </Select>    
        </Form.Item>
        
        <Form.Item
          label="Fulfillment Fee"
          name={"fulfillmentFee"}
        >
          <InputNumber />
        </Form.Item>

        <Form.Item
          label="From Postal Code:"
          name={"fromPostalCode"}
        >
          <Input  />
        </Form.Item>

        <Form.Item label="Kargo" name="carrier">
            <Select>
                <Select.Option value="shipentegra">Shipentegra</Select.Option>
                <Select.Option value="shipstation">Shipstation</Select.Option>
            </Select>    
        </Form.Item>

        <Form.Item label="Default Servis" name="preferredCargoService">
            <Select>
                <Select.Option value="usps_ground_advantage">usps_ground_advantage</Select.Option>
                <Select.Option value="usps_priority_mail">usps_priority_mail</Select.Option>
                <Select.Option value="ups_ground_saver">ups_ground_saver</Select.Option>
            </Select>    
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 20 }}>
          <Button style={{ width: "100%" }} type="primary" htmlType="submit">
            Onayla
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default UpdateVariationForm;

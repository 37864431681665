import React from "react";
import { Button, Modal, Space, Table } from "antd";
import type { TableProps } from "antd";
import UpdateVariationForm from "./UpdateVariationForm";
import adminService from "../../services/adminService";
import alertNotification from "../../utils/alertNotification";
import { get } from "http";

interface DataType {
  key: string;
  name: string;
  age: number;
  address: string;
  tags: string[];
  id:number
}


interface Props {
  data: any[];
  getProducts: () => Promise<void>
}

const ProductVariantTable: React.FC<Props> = ({ data,getProducts }) => {
  const [openModal, setOpenModal] = React.useState(false);
  const [selectedProduct, setSelectedProduct] = React.useState<any>(null);
  const deleteProduct = async (id: number) => {
    try {
      await adminService.deleteMarketplaceProductVariant(id);
      getProducts();
    } catch (error: any) {
      alertNotification("error", error.response.data.message);
    }
  }
  const columns: TableProps<DataType>["columns"] = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "SKU",
      dataIndex: "sku",
      key: "sku",
    },
    {
      title: "Fiyat",
      dataIndex: "price",
      key: "price",
    },
    {
      title: "Attributes",
      dataIndex: "attributeList",
      key: "attributeList",
    },
    {
      title: "Ağırlık",
      key: "weightValue",
      dataIndex: "weightValue",
    },

    {
      title: "Ağırlık Br.",
      key: "weightUnit",
      dataIndex: "weightUnit",
    },
    {
      title: "Fulfillment Fee",
      dataIndex: "fulfillmentFee",
      key: "fulfillmentFee",
    },
    {
      title: "Kargo",
      dataIndex: "carrier",
      key: "carrier",
    },
    {
      title: "Default Kargo servisi",
      dataIndex: "preferredCargoService",
      key: "preferredCargoService",
    },
    {
      title: "Düzenle",
      key: "edit",
      render: (text, record) => (
        <Space size="middle">
          <Button
            color="primary"
            onClick={() => {
              setSelectedProduct(record);
              setOpenModal(true);
            }}
          >
            Edit
          </Button>
        </Space>
      ),
    },
    {
      title: "Sil",
      key: "delete",
      render: (text, record) => (
        <Space size="middle">
          <Button
            color="primary"
            danger
            onClick={() => {
              deleteProduct(record.id);
              getProducts();
            }}
          >
            Sil
          </Button>
        </Space>
      ),
    },
  ];
  return <>
    <Table columns={columns} dataSource={data} />
    <Modal 
    width="70%"
    title="Varyasyonlar"
    footer={null}
    open={openModal}
    onOk={() => {
      setSelectedProduct(null);
      setOpenModal(false);
    }}
    onCancel={() => {
      setSelectedProduct(null);
      setOpenModal(false);
    }}
    >
      <UpdateVariationForm setOpenModal={setOpenModal} product={selectedProduct} getProducts={getProducts}/>
    </Modal>
  </>;
};

export default ProductVariantTable;

import React, { useEffect, useState } from "react";
import { Form, InputNumber, Button, Select, Space } from "antd";
import AdminService from "../../services/adminService"; // AdminService import edin

const { Option } = Select;

interface RuleDataFormProps {
  field: any;
  ruleType: string;
  remove: (name: number | number[]) => void;
}
const states = [
  "AK", "AL", "AR", "AZ", "CA", "CO", "CT", "DC", "DE", "FL", 
  "GA", "HI", "IA", "ID", "IL", "IN", "KS", "KY", "LA", "MA", 
  "MD", "ME", "MI", "MN", "MO", "MS", "MT", "NC", "ND", "NE", 
  "NH", "NJ", "NM", "NV", "NY", "OH", "OK", "OR", "PA", "RI", 
  "SC", "SD", "TN", "TX", "UT", "VA", "VT", "WA", "WI", "WV", "WY" ,
  "AMERIKA", "EU"
];
const RuleDataForm: React.FC<RuleDataFormProps> = ({ field, ruleType, remove }) => {
  const [suppliers, setSuppliers] = useState<{ id: number; name: string }[]>([]);
  const [sellerShopIds, setSellerShopIds] = useState<{ id: number; name: string }[]>([]);
  useEffect(() => {

    const fetchSuppliers = async () => {
      try {
        const response = await AdminService.getSuppliers(); // Supplierları getiren fonksiyon
        
        setSuppliers(response.data); // Gelen veriyi state'e set ediyoruz
      } catch (error) {
        console.error("Failed to fetch suppliers", error);
      }
    };

    fetchSuppliers();

    AdminService.getSellerShops()
      .then(res=>{
        console.log(res.data)
        setSellerShopIds(res.data)
      })
  }, []);

  return (
    <Space
      key={field.key}
      style={{ display: "flex", marginBottom: 8 }}
      align="baseline"
    >
      {ruleType === "location" && (
        <>
          <Form.Item
            {...field}
            name={[field.name, "supplierId"]}
            fieldKey={[field.fieldKey, "supplierId"]}
            rules={[{ required: true, message: "supplierId gerekli!" }]}
          >
            <Select placeholder="Select Supplier">
              {suppliers.map((supplier) => (
                <Option key={supplier.id} value={supplier.id}>
                  {supplier.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            {...field}
            name={[field.name, "price"]}
            fieldKey={[field.fieldKey, "price"]}
            rules={[{ required: true, message: "Price gerekli!" }]}
          >
            <InputNumber placeholder="Price" />
          </Form.Item>
          <Form.Item
            {...field}
            name={[field.name, "location"]}
            fieldKey={[field.fieldKey, "location"]}
            rules={[{ required: true, message: "Location gerekli!" }]}
          >
            <Select placeholder="Location">
              {states.map((state) => (
                <Option key={state} value={state}>
                  {state}
                </Option>
              ))}
            
            </Select>
          </Form.Item>
        </>
      )}

      {ruleType === "cycle" && (
        <>
          <Form.Item
            {...field}
            name={[field.name, "cycle"]}
            fieldKey={[field.fieldKey, "cycle"]}
            rules={[{ required: true, message: "Cycle gerekli!" }]}
          >
            <InputNumber placeholder="Cycle" />
          </Form.Item>
          <Form.Item
            {...field}
            name={[field.name, "price"]}
            fieldKey={[field.fieldKey, "price"]}
            rules={[{ required: true, message: "Price gerekli!" }]}
          >
            <InputNumber placeholder="Price" />
          </Form.Item>
          <Form.Item
            {...field}
            name={[field.name, "supplierId"]}
            fieldKey={[field.fieldKey, "supplierId"]}
            rules={[{ required: true, message: "Supplier ID gerekli!" }]}
          >
            <Select placeholder="Select Supplier">
              {suppliers.map((supplier) => (
                <Option key={supplier.id} value={supplier.id}>
                  {supplier.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            {...field}
            name={[field.name, "cycleCounter"]}
            fieldKey={[field.fieldKey, "cycleCounter"]}
            rules={[{ required: true, message: "Cycle Counter gerekli!" }]}
          >
            <InputNumber placeholder="Cycle Counter" />
          </Form.Item>
        </>
      )}

      {ruleType === "direct" && (
        <>
          <Form.Item
            {...field}
            name={[field.name, "price"]}
            fieldKey={[field.fieldKey, "price"]}
            rules={[{ required: true, message: "Price gerekli!" }]}
          >
            <InputNumber placeholder="Price" />
          </Form.Item>
          <Form.Item
            {...field}
            name={[field.name, "supplierId"]}
            fieldKey={[field.fieldKey, "supplierId"]}
            rules={[{ required: true, message: "Supplier ID gerekli!" }]}
          >
            <Select placeholder="Select Supplier">
              {suppliers.map((supplier) => (
                <Option key={supplier.id} value={supplier.id}>
                  {supplier.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </>
      )}

{ruleType === "seller" && (
        <>
          <Form.Item
            {...field}
            name={[field.name, "supplierId"]}
            fieldKey={[field.fieldKey, "supplierId"]}
            rules={[{ required: true, message: "supplierId gerekli!" }]}
          >
            <Select placeholder="Select Supplier">
              {suppliers.map((supplier) => (
                <Option key={supplier.id} value={supplier.id}>
                  {supplier.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            {...field}
            name={[field.name, "price"]}
            fieldKey={[field.fieldKey, "price"]}
            rules={[{ required: true, message: "Price gerekli!" }]}
          >
            <InputNumber placeholder="Price" />
          </Form.Item>
          <Form.Item
            {...field}
            name={[field.name, "sellerShopId"]}
            fieldKey={[field.fieldKey, "sellerShopId"]}
            rules={[{ required: true, message: "Location gerekli!" }]}
          >
            <Select placeholder="Seller">
              {sellerShopIds.map((sellerShopId) => (
                <Option key={sellerShopId.id} value={sellerShopId.id}>
                  {sellerShopId.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </>
      )}
      <Button type="link" onClick={() => remove(field.name)}>
        Sil
      </Button>
    </Space>
  );
};

export default RuleDataForm;